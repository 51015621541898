<template>
    <small v-if="validator.$errors.length > 0" class="text-[#F04438]" >
        {{ validator.$errors[0]?.$message }}
    </small>
</template>
<script>
export default {
    props: {
        validator: {
            type: Object,
            required: true
        }
    }
}
</script>